/**
 * Приведение статуса с сервера в удобоприменимый вид для клиента
 *
 * @param state {{id: string, translatedName: string, msTranslatedName: string, frontColor: string}}
 * @returns {{color: string, name: string, id: string}}
 */
// eslint-disable-next-line import/prefer-default-export
export const getStatus = (state) => ({
  id: state.id,
  name: state.translatedName || '',
  color: state.color,
  code: state.name, // TODO: удалить в будущем, но пока временное поле для хардкода (ESMPCP-1839)
});
